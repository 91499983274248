<template>
    <div class="library-expert-ins-component">
        <div class="block">
            <!-- <span class="demonstration">Click 指示器触发</span> -->
            <el-carousel trigger="click" height="150px">
                <el-carousel-item v-for="item in bannerData" :key="item.id">
                    <div class="swiper-img">
                        <img v-if="activeInd==1" :src="item.picture"  @click="goDetail(item.relateId)" alt="" />
                        <h3 :class="['small', activeInd==1? '': 'ins-small']">
                            <div>
                                <h6 @click="goDetail(item.relateId)">{{item.relateName}}</h6>
                                <p>{{item.description}}</p>
                            </div>
                            
                        </h3>
                    </div>

                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import img1 from '../assets/img/book.png'
import img2 from '../assets/img/book2.png'
import img3 from '../assets/img/book3.png'
import img4 from '../assets/img/book4.png'
export default {
    props: {
        bannerData: {
            type: Array,
            default: []
        },
        activeInd: {
            type: Number,
            default: 1
        },
        pageName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    mounted() {
        
    },
    methods: {
        goDetail (id) {
            
            if (this.activeInd == 1) {
                this.$router.push({path: '/expert/e', query: {id: id, pN: encodeURI(this.pageName)}})
            } else if (this.activeInd == 2) {
                this.$router.push({path: '/ins/i', query: {id: id, pN: encodeURI(this.pageName)}})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.com-banner-component {
    width: 278px;
    height: 150px;
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
}
</style>