<template>
    <div class="library-page">
        <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div>
        <div class="library-wrap">
            <div class="top-banner-wrap">
                <div class="wid1200">
                    <div class="top-banner-component">

                        <div class="left-top-banner">
                            <div class="left-top-banner-bg"></div>
                            <h5 v-if="bannerData.title">{{bannerData.title}}</h5>
                            <div>
                                <p class="banner-content" v-html="bannerData.content" style="color: #fff;">
                                </p>
                                <!-- <span>更多></span> -->
                            </div>

                        </div>
                        <div class="right-top-banner">
                            <top-banner :bannerData="imgBannerData" :pageName="pageName"></top-banner>
                        </div>
                    </div>
                </div>
            </div>

            <div class="library wid1200">
                <div class="left-library-wrap">
                <div class="left-library">
                    <div class="info-library-wrap com-border">
                        <div class="com-title">
                            <div>
                                <h5>{{libraryData.name}}</h5>
                            </div>
                        </div>
                        <div class="info-library">
                            <div class="top-info-library" v-html="libraryData.memo2">

                            </div>
                            <ul class="bottom-info-library">
                                <li v-for="i in childData" :key="i.id"  @click="goSecLibrary(i.id, i.name)" >[{{i.name}}]</li>
                            </ul>

                        </div>

                    </div>

                    <div class="dynamic-library-wrap com-border">
                        <div class="com-title">
                            <div>
                                <h5>学科资讯</h5>
                            </div>

                            <div>
                                <span @click="goNews">更多<img src="../assets/img/more.png" alt="" /></span>
                            </div>
                        </div>
                        <div class="dynamic-library">
                            <div class="left-dynamic-library">
                                <img v-if="dynamicData && dynamicData[0] && dynamicData[0].titleImg"
                                    :src="dynamicData[0].titleImg" alt="" @click="goNewsDetail(dynamicData[0])"/>
                                <img v-else alt="" />
                            </div>
                            <ul class="right-dynamic-library">
                                <li v-for="it in dynamicData" :key="it.id">
                                    <p v-if="it.title" @click="goNewsDetail(it)">{{it.title}}</p>
                                    <span>{{it.releaseDate}}</span>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="subject-library-wrap com-border" v-for="i in childData" :key="i.id">
                        <div class="com-title">
                            <div>
                                <h5>{{i.name}}</h5>
                            </div>

                            <div>
                                <span @click="goSecLibrary(i.id, i.name)" >更多<img src="../assets/img/more.png" alt="" /></span>
                            </div>
                        </div>
                        <div class="subject-library">
                            <div class="first-subject-library">
                                <div class="left-first-subject">
                                    <!-- <img v-if="i && i.childList && i.childList[0] && i.childList[0].img"
                                        :src="i.childList[0].img" alt="" /> -->
                                    <img v-if="i.logo1" :src="i.logo1" @click="goArticle(i.childList[0])" alt="" />
                                    <img v-else alt="" />
                                </div>
                                <div class="right-first-subject">
                                    <h5 v-if="i.resourceType == 'ARTICLE'  && i && i.childList && i.childList[0] && i.childList[0].title" @click="goArticle(i.childList[0])">

                                        <img v-if="i.childList[0].openAccessStatus == 1" src="../assets/img/lock.png" alt="" style="width:19px; height: 19px; vertical-align: middle;"/>
                                         {{i.childList[0].searchTitle ? i.childList[0].searchTitle :i.childList[0].title}}</h5>

                                    <h5 v-if="i.resourceType != 'ARTICLE'  && i && i.childList && i.childList[0] && i.childList[0].title" @click="goArticle(i.childList[0])">
                                        <img v-if="i.childList[0].openAccessStatus == 1" src="../assets/img/lock.png" alt="" style="width:19px; height: 19px; vertical-align: middle;"/>
                                        {{i.childList[0].title}}
                                    </h5>

                                    <p v-if="i && i.childList && i.childList[0] && i.childList[0].abstractCn"
                                       v-html="i.childList[0].abstractCn"></p>
                                </div>
                            </div>
                            <ul class="list-subject-library">

                                <li v-for="(item, ind) in i.childList" :key="item.id" v-if="ind>0" >
                                    <img v-if="item.openAccessStatus == 1" src="../assets/img/lock.png" alt="" />

                                    <p v-if="item.resourceType == 'ARTICLE'" @click="goArticle(item)">{{item.searchTitle? item.searchTitle : item.title}}</p>
                                    <p v-if="item.resourceType != 'ARTICLE' && item.title" @click="goArticle(item)">{{item.title}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
                <div class="right-library">
                    <div class="newest-resource-wrap com-border">
                        <div class="com-title">
                            <div>
                                <img class="title-img" src="../assets/img/newicon.png" alt="" />
                                <h5>推荐资源</h5>
                            </div>

                            <!-- <div>
                                <span>更多<img src="../assets/img/more.png" alt="" /></span>
                            </div> -->
                        </div>
                        <div class="newest-resource">
                            <div class="btn-wrap">
                                <button :class="[wenjiBookInd == 1 ? 'active-wenji-book': '']"
                                    @click="switchWenjiBook(1)">集刊</button>
                                <button :class="[wenjiBookInd == 2 ? 'active-wenji-book': '']"
                                    @click="switchWenjiBook(2)">文集</button>
                            </div>
                            <div class="content-newest-resource">
                                <div class="left-content-newest-resource">
                                    <img v-if="wenjiBookInd == 2 && wenjiData && wenjiData[0] && wenjiData[0].img" :src="wenjiData[0].img"
                                        alt="" />

                                    <img v-if="wenjiBookInd == 1 && bookData && bookData[0] && bookData[0].img" :src="bookData[0].img"
                                        alt="" />

                                </div>
                                <div class="right-content-newest-resource">
                                    <h5 v-if="wenjiBookInd == 2 && wenjiData && wenjiData[0] && wenjiData[0].title" @click="goWenji(wenjiData[0].id, wenjiData[0].title)">
                                        {{wenjiData[0].title}}</h5>
                                    <p v-if="wenjiBookInd == 2 && wenjiData && wenjiData[0] && wenjiData[0].abstractCn">
                                        {{wenjiData[0].abstractCn}}
                                    </p>

                                    <h5 v-if="wenjiBookInd == 1 && bookData && bookData[0] && bookData[0].title" @click="goCollected(bookData[0].id, bookData[0].title)">
                                        {{bookData[0].title}}</h5>
                                    <p v-if="wenjiBookInd == 1 && bookData && bookData[0] && bookData[0].abstractCn">
                                        {{bookData[0].abstractCn}}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="expert-ins-wrap com-border">
                        <div class="title-expert-ins">
                            <div :class="['left-title-expert-ins', 'expert-ins-title', activeExpIns==1 ? 'active-expert-ins-title' : '']"
                                @click="switchExpIns(1)">
                                <img src="../assets/img/expert.png" alt="" />
                                <p>学者</p>
                            </div>
                            <div :class="['right-title-expert-ins', 'expert-ins-title', activeExpIns==2 ? 'active-expert-ins-title' : '']"
                                @click="switchExpIns(2)">
                                <img src="../assets/img/ins.png" alt="" />
                                <p>机构</p>
                            </div>
                        </div>
                        <div class="expert-ins">
                            <expert-ins v-if="activeExpIns==1" :bannerData="expertData" :pageName="pageName"
                                :activeInd="activeExpIns"></expert-ins>
                            <expert-ins v-if="activeExpIns==2" :bannerData="insData" :pageName="pageName"
                                :activeInd="activeExpIns"></expert-ins>
                        </div>
                    </div>

                    <div class="open-access-wrap com-border  com-right">
                        <div class="com-title">
                            <div>
                                <img class="title-img" src="../assets/img/oa.png" alt="" />
                                <h5>开放获取</h5>
                            </div>

                            <div>
                                <span @click="goOa">更多<img src="../assets/img/more.png" alt="" /></span>
                            </div>
                        </div>
                        <div class="open-access">
                            <p class="com-p" v-for="(item, index) in openData" :key="index" v-if="item.title" @click="goOaDetail(item)"><img
                                    v-if="item.openAccessStatus == 1" src="../assets/img/lock.png" alt="" />
                                <span v-if="item.resourceType == 'ARTICLE'">{{item.searchTitle? item.searchTitle : item.title}}</span>

                                <span v-if="item.resourceType != 'ARTICLE'">{{item.title}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="new-preprint-wrap com-border  com-right">
                        <div class="com-title">
                            <div>
                                <img class="title-img" src="../assets/img/preprinticon.png" alt="" />
                                <h5>预印本</h5>
                            </div>

                            <div>
                                <span>
                                    <router-link :to="{path: '/preprint/p', query: {pN: encodeURI(pageName)}}">更多<img src="../assets/img/more.png" alt="" /></router-link>
                                </span>
                            </div>
                        </div>
                        <div class="new-preprint">
                            <p class="com-p" v-for="item in preprintData" :key="item.id" v-if="item.title" @click="goPreprint(item)">
                                {{item.title}} </p>
                        </div>
                    </div>
                    <div class="new-special-wrap com-border  com-right">
                        <div class="com-title">
                            <div>
                                <img class="title-img" src="../assets/img/specialicon.png" alt="" />
                                <h5>专题</h5>
                            </div>

                            <div>
                                <span @click="goSubjectList">
                                    更多<img src="../assets/img/more.png" alt="" />
                                </span>
                            </div>
                        </div>



                        <div class="new-special ">
                            <ul class="top-new-special">
                                <li>
                                    <img v-if="specialData && specialData[0]" :src="specialData[0].logo1"  @click="goSubject(specialData[0].id)" alt="" />
                                    <img v-else  alt="" />
                                    <p v-if="specialData && specialData[0]" @click="goSubject(specialData[0].id)">{{specialData[0].name}}</p>
                                </li>
                                <li>
                                    <img v-if="specialData && specialData[1]" :src="specialData[1].logo1" @click="goSubject(specialData[1].id)" alt="" />
                                    <img v-else alt="" />
                                    <p v-if="specialData && specialData[1]" @click="goSubject(specialData[1].id)">{{specialData[1].name}}</p>
                                </li>
                            </ul>
                            <p class="com-p" v-for="(item, index) in specialData" v-if="index>1" :key="index"  @click="goSubject(item.id)">
                                {{item.name}}</p>
                        </div>
                    </div>
                    <div class="new-media-wrap com-border com-right">
                        <div class="com-title">
                            <div>
                                <img class="title-img" src="../assets/img/mediaicon.png" alt="" />
                                <h5>多媒体资源</h5>
                            </div>

                            <div>
                                <router-link to='/chart/c'><span>更多<img src="../assets/img/more.png" alt="" /></span>
                                </router-link>
                            </div>
                        </div>
                        <div class="new-media">
                            <ul>
                                <li :class="chartPictureInd==1? 'active-new-media' : ''"
                                    @click="getchartData('CHART', 1)">图表</li>
                                <li :class="chartPictureInd==2? 'active-new-media' : ''"
                                    @click="getchartData('PICTURE', 2)">图片</li>
                            </ul>



                            <img v-if="chartPictureInd==1 && chartData && chartData[0]" :src="chartData[0].img" @click="chartDetail(chartData[0].id)" alt="" />
                            <h6 v-if="chartPictureInd==1 && chartData && chartData[0]" @click="chartDetail(chartData[0].id)" >{{chartData[0].title}}</h6>
                            <p class="com-p" @click="chartDetail(chartData[1].id)" v-if="chartPictureInd==1 && chartData[1] && chartData[1].title">
                                {{chartData[1].title}}</p>

                            <img v-if="chartPictureInd==2 && pictureData && pictureData[0]" :src="pictureData[0].img" alt="" @click="chartDetail(pictureData[0].id)"/>
                            <h6 v-if="chartPictureInd==2 && pictureData && pictureData[0]" @click="chartDetail(pictureData[0].id)">{{pictureData[0].title}}</h6>
                            <p class="com-p" v-if="chartPictureInd==2 && pictureData[1] && pictureData[1].title" @click="chartDetail(pictureData[1].id)">
                                {{pictureData[1].title}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import BreadCrumb from '../components/BreadCrumb'
import TopBanner from '../components/ComBanner'
import ExpertIns from '../components/LibraryExpertIns'

import img1 from '../assets/img/book.png'

export default {
    components: { BreadCrumb, TopBanner, ExpertIns },
    data() {
        return {
            pageName: '',
            pN: '',
            activeExpIns: 1,
            bannerData: {
                title: '',
                content: '.'
            },
            wenjiBookInd: 1,
            expertData: [],
            insData: [],
            wenjiData: [],
            chartData: [],
            pictureData: [],
            bookData: [],
            chartPictureInd: 1,
            specialData: [],
            preprintData: [],
            openData: [], //开放获取
            chartPictureInd: 1,
            chartData: [], //多媒体资源   图片图表
            libraryData: {},
            childData: [],
            imgBannerData: [],
            dynamicData: []
        }
    },
    watch: {
        $route: 'init'
    },
    methods: {
        init() {
            this.clear()
            this.getDetail()
            this.getChildList()
            this.getOa()
            this.getPrePrint()

            // this.getBanner()
            //             子库页面：
            // 获取子库详情：		/api/library/detail		id
            // 子库的推荐学者：		/api/library/scholar		libraryId
            // 子库的推荐机构：		/api/library/archive		libraryId
        },
        goPreprint (item) {
            this.$router.push({path: '/pD/p', query: {id: item.id, pN: encodeURI(this.pageName)}})
        },
        goSecLibrary (id, title) {
            this.$router.push({ path: '/secLibrary/s', query: { id: id, title: title, pN: encodeURI(this.pageName) } })
        },
        goWenji (id, title) {
            this.$router.push({ path: '/collectionDetail/c', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        goCollected (id, title) {
            this.$router.push({ path: '/collectionDetail/c', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        clear() {
            this.pageName = ''
            this.pN = ''
            this.activeExpIns = 1
            this.bannerData = {
                title: '',
                content: '.'
            }
            this.wenjiBookInd = 1
            this.expertData = []
            this.insData = []
            this.wenjiData = []
            this.chartData = []
            this.pictureData = []
            this.bookData = []
            this.chartPictureInd = 1
            this.specialData = []
            this.preprintData = []
            this.openData = []
            this.chartData = []
            this.libraryData = {}
            this.childData = []
            this.imgBannerData = []
            this.dynamicData = []
        },
        goArticle(item) {
            // console.log('item', item )
            if (item.resourceType == 'COLLECTED_PAPERS') {
                this.$router.push({path: '/c/c', query: {id: item.id, pN: encodeURI(this.pageName)}})
            } else if (item.resourceType == 'WENJI'  || item.resourceType == 'BOOK') {
                this.$router.push({path: '/collectionDetail/c', query: {id: item.id, pN: encodeURI(this.pageName)}})
            } else if (item.resourceType == 'ARTICLE') {
                this.$router.push({path: '/aD/a', query: {id: item.id, pN: encodeURI(this.pageName)}})
            }
        },


        goNews () {
            // _this.libraryData.nameAlias + '_xwdt'
            this.$router.push({path: '/news/n', query: {id: this.libraryData.nameAlias + '_xwdt', pN: encodeURI(this.pageName)}})
        },
        goSubjectList () {
            this.$router.push({ path: '/subject/subject', query: {id: this.$route.query.id, pN: encodeURI(this.pageName)} })
        },
        goNewsDetail (item) {
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else {
                this.$router.push({path: '/nD/n', query: {id: item.id, pN: encodeURI(this.pageName)}})
            }
            // this.$router.push({path: '/nD/n', query: {id: id, pN: encodeURI(this.pageName)}})
        },
        getImg(id) {
            var _this = this
            _this
                .$http({
                    method: 'get',
                    url: '/admin/api/library/detail?id=' + e.id
                })
                .then(res => {
                    if (res.data.code == 0) {
                        var data = res.data.data
                        // resolve(data)
                    }
                })
        },

        switchWenjiBook(num) {
            this.wenjiBookInd = num
        },
        getchartData(type, num) {
            this.chartPictureInd = num
        },
        goSubject(id) {
            this.$router.push({ path: '/subjectDetail/s', query: { id: id, pN: encodeURI(this.pageName)  } })
        },
        goOa () {
            this.$router.push({path: '/oa/openaccess', query: {pN: encodeURI(this.pageName)}})
        },
        goOaDetail (item) {
            //  /aD?pN=&title=体育与城市：从发展竞争到治理能力
            // this.$router.push({ path: '/aD/a', query: { id: id,  pN: encodeURI(this.pageName) } })

            if (item.resourceType == 'COLLECTED_PAPERS') {
                this.$router.push({path: '/c/c', query: {id: item.id,  pN: encodeURI(this.pageName)}})
            } else if (item.resourceType == 'WENJI'  || item.resourceType == 'BOOK') {
                this.$router.push({path: '/collectionDetail/c', query: {id: item.id,  pN: encodeURI(this.pageName)}})
            } else if (item.resourceType == 'ARTICLE') {
                this.$router.push({path: '/aD/a', query: {id: item.id,  pN: encodeURI(this.pageName)}})
            }
            // title: title,
        },
        chartDetail(id) {
            this.$router.push({ path: '/chartDetail/c', query: { id: id, pN: encodeURI(this.pageName)} })
        },
        getOa() {
            // var _this = this
            // this.$http({
            //     method: 'get',
            //     url: '/admin/api/library/resource',
            //     params: {
            //         libraryId: this.$route.query.id,
            //         resourceTypeList: 'ARTICLE,BOOK',
            //         pageSize: 7,
            //         pageNum: 1,
            //         openAccessStatus: 1
            //     }
            // }).then(res => {
            //     if (res.data.code == 0) {
            //         _this.openData = res.data.data.rows
            //     }
            // })

            var _this = this
            this.$http({
                method: 'post',
                url: '/admin/api/search/find',
                data: {
                    pageSize: 7,
                    pageNo: 1,
                    openAccessStatus: 1,
                    resourceType: 7
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.openData = res.data.data.rows
                }
            })
        },
        getBookWenjiPreprintMedia(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/library/detail?id=' + id
            }).then(res => {
                if (res.data.code == 0) {
                    this.getBookWenjiPreprintMedia2(res.data.data.id)
                    // if (res.data.data.name=='推荐信息') {

                    // } else {
                    //     console.log(111111, res.data)
                    // }
                }
            })
        },
        getBookWenjiPreprintMedia2(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/library/list?parentId=' + id
            }).then(res => {
                if (res.data.code == 0) {
                    var x = res.data.data.rows
                    x.forEach(e => {
                        if (e.name == '推荐文集') {
                            this.getBookWenjiPreprintMedia3(e.id, 'WENJI', 1, 1)
                            // http://jikan.a.com/admin/api/library/resource?libraryId=37&resourceType=BOOK&pageSize=1&pageNum=1
                        } else if (e.name == '推荐集刊') {
                            this.getBookWenjiPreprintMedia3(e.id, 'BOOK', 1, 1)
                        }
                        // else if (e.name == '推荐预印本') {
                        //     this.getBookWenjiPreprintMedia3(e.id, 'ALONE_ARTICLE', 1, 5)
                        // }
                    })
                    this.getBookWenjiPreprintMedia3(_this.$route.query.id, 'PICTURE', 1, 2)
                    this.getBookWenjiPreprintMedia3(_this.$route.query.id, 'CHART', 1, 2)
                }
            })
        },
        getBookWenjiPreprintMedia3(id, type, num, size) {
            // http://jikan.a.com/admin/api/library/resource?libraryId=37&resourceType=BOOK&pageSize=1&pageNum=1
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/library/resource',
                params: {
                    libraryId: id,
                    resourceType: type,
                    pageSize: size,
                    pageNum: num
                }
            }).then(res => {
                if (res.data.code == 0) {
                    // console.log(type, res.data.data)
                    var data = res.data.data
                    if (type == 'WENJI') {
                        _this.wenjiData = data.rows
                        if (_this.wenjiData &&_this.wenjiData[0] && _this.wenjiData[0].abstractCn) {
                            _this.wenjiData[0].abstractCn = _this.switchHtml(_this.wenjiData[0].abstractCn)
                        }
                    } else if (type == 'BOOK') {
                        _this.bookData = data.rows
                        if (_this.bookData && _this.bookData[0] && _this.bookData[0].abstractCn) {
                            _this.bookData[0].abstractCn = _this.switchHtml(_this.bookData[0].abstractCn)
                        }
                    } else if (type == 'ALONE_ARTICLE') {
                        // _this.preprintData = data.rows
                    } else if (type == 'CHART') {
                        _this.chartData = data.rows
                    } else if (type == 'PICTURE') {
                        _this.pictureData = data.rows
                    }
                }
            })
        },
        switchHtml (x) {
            var msg = x.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n').replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '').replace(/(\n)?<\/([^>]+)>/g, '').replace(/\u00a0/g, ' ').replace(/&nbsp;/g, ' ').replace(/<\/?(img)[^>]*>/gi, '').replace(/&amp;/g,"&").replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(/&#39;/g,"\'").replace(/&quot;/g,"\"").replace(/<\/?.+?>/g,"")
            return msg
        },
        getPrePrint () {
            var _this = this
            // this.$http({
            //     method: 'get',
            //     url: '/admin/api/library/resource?resourceType=ALONE_ARTICLE&pageSize=7&pageNum=1&libraryId='+ this.$route.query.id
            // }).then(res => {
            //     if (res.data.code == 0) {
            //         _this.preprintData = res.data.data.rows
            //     }
            // })
            var _this = this
            this.$http.get('/admin/api/aloneArticle/articleList').then(res => {
                if (res.data.code == 0) {
                    _this.preprintData = res.data.data.rows.slice(0, 7)
                }
            })
        },
        getDetail() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/library/detail?id=' + this.$route.query.id
            }).then(res => {
                if (res.data.code == 0) {
                    var data = res.data.data
                    _this.pageName = res.data.data.name
                    // console.log('_this.pageName', _this.pageName)
                    _this.libraryData = data

                    _this.expertData = data.scholarList
                    _this.insData = data.researchList
                    if (data.recommendSubjectList && data.recommendSubjectList.length>0) {
                        _this.specialData = data.recommendSubjectList.splice(0, 4)
                    } else {
                        _this.specialData = []
                    }
                    // console.log('专题_this.specialData', _this.specialData)

                    _this.getNewsData(_this.libraryData.nameAlias + '_lbt')
                    _this.getNewsData(_this.libraryData.nameAlias + '_xwdt')

                    _this.bannerData.title = data.name
                    _this.bannerData.content = data.memo1
                }
            })
        },
        getChildList() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/library/list?parentId=' + this.$route.query.id
            }).then(res => {
                if (res.data.code == 0) {
                    var x = res.data.data.rows
                    var recommendInd
                    // console.log('res ', res.data.data.rows)
                    x.forEach((x, ind) => {
                        if (x.name == '推荐信息') {
                            this.getBookWenjiPreprintMedia(x.id)
                            recommendInd = ind
                        }
                    })
                    var y = x
                    y.splice(recommendInd, 1)
                    // for (var i=0; i<y.length; i++){
                    //     _this.getArticleBook(y[i].id).then(res => {
                    //         var l = {}
                    //         l = y[i]
                    //         l.childList = res
                    //         _this.childData.push(l)
                    //     })
                    // }

                    // y.forEach(e => {
                    //     _this.getArticleBook(e.id).then(res => {
                    //         var l = {}
                    //         l = e
                    //         l.childList = res
                    //         _this.childData.push(l)
                    //     })
                    // })

                    // var curArray = []
                    // y.forEach((e, ind) => {
                    //     console.log('name1', e.name)
                    // var result = await _this.getArticleBook(e.id)
                    //     curArray[ind] = result

                    //     // _this.getArticleBook(e.id).then(res => {
                    //     //     var l = {}
                    //     //     l = e
                    //     //     l.childList = res
                    //     //     console.log('name2', l.name)
                    //     //     _this.childData.push(l)
                    //     // })
                    // })

                    Promise.all(
                        y.map(function(e) {
                            return new Promise(function(resolve, reject) {
                                _this
                                    .$http({
                                        method: 'get',
                                        url: '/admin/api/library/resource',
                                        params: {
                                            libraryId: e.id, //  		子库id
                                            resourceTypeList: 'ARTICLE,BOOK,WENJI', // 	资源类型
                                            pageSize: 7, //		页面列表长度
                                            pageNum: 1
                                        }
                                    })
                                    .then(res => {
                                        if (res.data.code == 0) {
                                            resolve(res.data.data.rows)
                                        }
                                    })
                                    .catch(err => {
                                        reject(err)
                                    })
                            })
                        })
                    ).then(function(data) {
                        //在这就可以等所有的返回结果可以得到
                        _this.childData = []
                        // console.log('data', data)
                        y.forEach((e, ind) => {
                            var l = {}
                            l = e
                            l.childList = data[ind]
                            _this.childData.push(l)
                            // console.log('_this.childData', _this.childData)
                        })
                    })
                    // console.log('curArray', curArray)
                }
            })
        },
        getArticleBook(id) {
            var _this = this
            return new Promise(function(resolve, reject) {
                _this
                    .$http({
                        method: 'get',
                        url: '/admin/api/library/resource',
                        params: {
                            libraryId: id, //  		子库id
                            resourceTypeList: 'ARTICLE,BOOK', // 	资源类型
                            pageSize: 7, //		页面列表长度
                            pageNum: 1
                        }
                    })
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve(res.data.data.rows)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getNewsData(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list?innerCode=' + id
            }).then(res => {
                if (id.slice(id.length - 3, id.length) == 'lbt') {
                    //轮播图
                    _this.imgBannerData = res.data.data.rows
                } else {
                    //学科资讯
                    _this.dynamicData = res.data.data.rows.splice(0, 5)
                }
            })
        },
        getBanner() {
            var _this = this
            this.$http({
                method: 'get',
                url: ''
            })
        },
        switchExpIns(num) {
            this.activeExpIns = num
        }
    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
.library-page {
    .com-border {
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 26px;
        .com-title {
            div img {
                margin-right: 10px;
            }
        }
    }
    .library-wrap {
        .top-banner-wrap {
            width: 100%;
            background: #cb2f3c;
            min-height: 300px;
            .top-banner-component {
                display: flex;
                justify-content: space-between;
                .left-top-banner {
                    width: 380px;
                    height: 250px;
                    margin-top: 25px;
                    padding: 18px;
                    box-sizing: border-box;
                    position: relative;
                    z-index: 0;
                    h5 {
                        font-size: 22px;
                        line-height: 22px;
                        color: #fff;
                        margin-bottom: 12px;
                    }
                    div {
                        z-index: 10;
                        .banner-content {
                            font-size: 14px;
                            line-height: 26px;
                            color: #fff;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 7;
                            -webkit-box-orient: vertical;
                            p {
                                color: #fff;
                            }
                        }
                        span {
                            font-size: 16px;
                            color: #fcbb0b;
                            float: right;
                            margin-top: 4px;
                            cursor: pointer;
                        }
                    }
                    .left-top-banner-bg {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 380px;
                        height: 250px;
                        background-image: url('../assets/img/bannerbg.png');
                        background-size: 100% 100%;
                        opacity: 0.4;
                        z-index: -1;
                    }
                }
            }
        }
        .library {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            .left-library {
                width: 860px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .info-library-wrap {
                    width: 860px;
                    height: 282px;
                    padding: 10px 20px 0;
                    .com-title {
                        border-bottom: none;
                    }
                    .info-library {
                        .top-info-library {
                            line-height: 28px;
                            font-size: 16px;
                            margin-top: 12px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4; // 控制多行的行数
                            -webkit-box-orient: vertical;
                        }
                        ul.bottom-info-library {
                            display: flex;
                            flex-wrap: wrap;
                            // justify-content: space-between;
                            margin-top: 12px;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // display: -webkit-box;
                            // -webkit-line-clamp: 2; // 控制多行的行数
                            // -webkit-box-orient: vertical;
                            li {
                                width: 200px;
                                padding-left: 10px;
                                position: relative;
                                font-size: 16px;
                                color: #bd1a2d;
                                text-align: left;
                                box-sizing: border-box;
                                line-height: 40px;
                                cursor: pointer;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            li::before {
                                content: '';
                                position: absolute;
                                width: 4px;
                                height: 4px;
                                background: #ffac13;
                                border-radius: 50%;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
                .dynamic-library-wrap {
                    width: 860px;
                    height: 250px;
                    padding: 10px 20px 0;
                    .com-title {
                        border-bottom: none;
                    }
                    .dynamic-library {
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        .left-dynamic-library {
                            img {
                                width: 285px;
                                height: 160px;
                                cursor: pointer;
                            }
                        }
                        ul.right-dynamic-library {
                            width: 510px;
                            li {
                                width: 510px;
                                display: flex;
                                align-items: top;
                                justify-content: space-between;
                                height: 35px;
                                cursor: pointer;
                                p {
                                    font-size: 16px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    padding-left: 10px;
                                    box-sizing: border-box;
                                    flex: 1;
                                    position: relative;
                                }
                                p::before {
                                    content: '';
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background: #ffac13;
                                    position: absolute;
                                    left: 0;
                                    top: 11px;
                                }
                                span {
                                    font-size: 16px;
                                    color: #666666;
                                    width: 98px;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
                .subject-library-wrap {
                    width: 418px;
                    height: 409px;
                    padding: 10px 20px 0;
                    .com-title {
                        border-bottom: none;
                    }
                    .subject-library {
                        .first-subject-library {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;
                            .left-first-subject {
                                img {
                                    width: 140px;
                                    height: 88px;
                                    cursor: pointer;
                                }
                            }
                            .right-first-subject {
                                width: 221px;
                                h5 {
                                    width: 221px;
                                    font-size: 16px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                }
                                p {
                                    width: 221px;
                                    font-size: 14px;
                                    line-height: 21px;
                                    margin-top: 4px;
                                    color: #666;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3; // 控制多行的行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                        .list-subject-library {
                            li {
                                height: 39px;
                                display: flex;
                                align-items: center;
                                position: relative;
                                box-sizing: border-box;
                                padding-left: 10px;

                                img {
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 5px;
                                }
                                p {
                                    font-size: 16px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    cursor: pointer;
                                }
                            }
                            li::before {
                                position: absolute;
                                content: '';
                                left: 0;
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #ffac13;
                            }
                        }
                    }
                }
            }
            .right-library {
                width: 318px;
                .newest-resource-wrap {
                    width: 316px;
                    padding: 10px 20px 12px;
                    .com-title {
                        div:first-child {
                            img {
                                width: 28px;
                                height: 15px;
                                margin-right: 5px;
                            }
                        }
                    }
                    .newest-resource {
                        .btn-wrap {
                            margin-top: 14px;
                            margin-bottom: 14px;
                            display: flex;
                            justify-content: space-between;
                            button {
                                width: 133px;
                                height: 36px;
                                line-height: 36px;
                                text-align: center;
                                background: #f0f0f0;
                                font-size: 16px;
                                padding: 0;
                                border: none;
                            }
                            .active-wenji-book {
                                background: #bd1a2d;
                                color: #fff;
                            }
                        }
                        .content-newest-resource {
                            display: flex;
                            justify-content: space-between;
                            height: 148px;

                            .left-content-newest-resource {
                                img {
                                    width: 106px;
                                    height: 142px;
                                    cursor: pointer;
                                    box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                                }
                            }
                            .right-content-newest-resource {
                                width: 160px;
                                h5 {
                                    width: 160px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    font-size: 16px;
                                    cursor: pointer;
                                }
                                p {
                                    width: 160px;
                                    margin-top: 8px;
                                    font-size: 14px;
                                    line-height: 22px;
                                    font-size: 14px;
                                    color: #666666;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5; // 控制多行的行数
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }

                .expert-ins-wrap {
                    padding: 0 20px;
                    .title-expert-ins {
                        display: flex;
                        .left-title-expert-ins {
                            img {
                                width: 28px;
                                height: 20px;
                            }
                            p {
                            }
                        }
                        .right-title-expert-ins {
                            img {
                                width: 21px;
                                height: 21px;
                            }
                            p {
                            }
                        }
                        .expert-ins-title {
                            display: flex;
                            height: 47px;
                            align-items: center;
                            width: 138px;
                            border-bottom: 2px solid #dedede;
                            background: #f0f0f0;
                            justify-content: center;
                            margin-bottom: 20px;
                            cursor: pointer;
                            img {
                            }
                            p {
                                width: 48px;
                                color: #666;
                                margin-left: 6px;
                                font-size: 24px;
                                line-height: 47px;
                                box-sizing: border-box;
                            }
                        }
                        .active-expert-ins-title {
                            background: #fff;
                            p {
                                color: #000;
                                border-bottom: 3px solid #bd1a2d;
                            }
                        }
                    }
                    .expert-ins {
                        height: 178px;
                    }
                }
            }
        }
    }
}

.open-access-wrap {
    // padding: 0 20px;
    // width: 316px;
    height: 380px;
    padding: 10px 20px;
    box-sizing: border-box;
    // box-sizing: border-box;
    // background: #fefefe;
    // border: 1px solid #f5f5f5;
    // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    // margin: 24px 0;
    .com-title {
        div {
            img.title-img {
                width: 28px;
                height: 15px;
                margin-right: 10px;
            }
        }
    }
    .open-access {
        padding-top: 20px;
        p {
            width: 276px;

            padding-left: 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            position: relative;
            height: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            img {
                width: 19px;
                height: 19px;
                margin-right: 5px;
            }
            span {
                display: block;
                font-size: 16px;
                color: #333;
                width: 242px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        p::before {
            content: '';
            position: absolute;
            left: 0;
            width: 4px;
            height: 4px;
            background: #ffac13;
            border-radius: 50%;
        }
    }
}
.new-preprint-wrap {
    height: 364px;
    padding: 10px 20px;
    box-sizing: border-box;
    .com-title {
        div {
            img.title-img {
                width: 29px;
                height: 29px;
                margin-right: 10px;
            }
            span {
                a {
                    color: #404040;
                }
            }
        }
    }
    .new-preprint {
        margin-top: 20px;
        .com-p {
            width: 276px;
            line-height: 17px;
            margin: 0 auto 18px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
            display: inline-block;
            position: relative;
            cursor: pointer;
        }
        .com-p::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
}
.new-special-wrap {
    // padding: 0 20px;
    // width: 316px;
    // box-sizing: border-box;
    // background: #fefefe;
    // border: 1px solid #f5f5f5;
    // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    // margin: 24px 0;
    padding: 10px 20px;
    .new-special {
        .top-new-special {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            li {
                width: 134px;
                height: 104px;
                margin-bottom: 15px;
                img {
                    display: block;
                    width: 100%;
                    height: 87px;
                    cursor: pointer;
                }
                p {
                    line-height: 17px;
                    height: 17px;
                    width: 134px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    background: #d1d1d1;
                    font-size: 12px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
        .com-p {
            width: 276px;
            line-height: 17px;
            margin: 0 auto 18px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
            display: inline-block;
            position: relative;
            cursor: pointer;
        }
        .com-p::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
}
.new-media-wrap {
    padding: 10px 20px;
    .new-media {
        margin-top: 20px;
        position: relative;
        ul {
            display: flex;
            justify-content: space-between;
            li {
                width: 127px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                background: #efefef;
                color: #000000;
                cursor: pointer;
            }
            .active-new-media {
                color: #fff;
                background: #bd192d;
            }
        }
        img {
            cursor: pointer;
        }
        h6 {
            position: absolute;
            left: 0;
            top: 183px;
            width: 281px;
            font-size: 14px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff;
            background: rgba(2, 2, 2, 0.5);
            padding-left: 10px;
            box-sizing: border-box;
            cursor: pointer;
        }
        .com-p {
            cursor: pointer;
            width: 276px;
            line-height: 17px;
            margin: 0 auto 18px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
            display: inline-block;
            position: relative;
        }
        .com-p::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: 0;
            top: 8px;
        }

        img {
            width: 281px;
            height: 175px;
            margin: 13px auto 16px;
        }
    }
}
</style>
